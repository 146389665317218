import React, { useState } from 'react';
import { FiEdit2, FiXCircle } from 'react-icons/fi';
import axios from 'axios';
import EditRowModal from './EditRowModal';
import {
  baseUrl,
  headers,
  organisations,
  validEmail,
  validEmailHelp,
} from './lookups';

export default function Table({
  setRows,
  rows,
  uploadedBy,
  uploadedByEmail,
  uploadedByJob,
  validated,
  setValidated,
}) {
  const [showModal, setShowModal] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
  const [displayFailureMessage, setDisplayFailureMessage] = useState(false);
  const [message, setMessage] = useState(null);
  const [errors, setErrors] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');

  const handleDeleteRow = (idx) => {
    setValidated(false);
    setRows(rows.filter((row, index) => idx !== index));
  };

  const handleDeleteAll = () => {
    setValidated(false);
    setRows([]);
  };

  const handleEditRow = (row) => {
    // open modal and then set row to display there for editing
    setShowModal(true);
    setRowToEdit(row);
    setValidated(false);
  };

  const handleAddRow = () => {
    // open modal, then add emoty row to end of rows array
    setShowModal(true);
    setValidated(false);
    setRowToEdit({
      firstname: '',
      surname: '',
      email: '',
      job: '',
      organisation: null,
      id: rows.length + 1,
      errors: [],
      other: '',
    });
  };
  const handleRegisterFreshworksIndividual = async (freshworksRows) => {
    try {
      await Promise.all(
        freshworksRows.map((row) =>
          axios.post(
            `${baseUrl}/user-service/freshsales-users`,
            {
              name: `${
                row.firstname.charAt(0).toUpperCase() +
                row.firstname.toLowerCase().slice(1)
              } ${row.surname.charAt(0).toUpperCase()}${row.surname
                .toLowerCase()
                .slice(1)}`,
              first_name:
                row.firstname.charAt(0).toUpperCase() +
                row.firstname.toLowerCase().slice(1),
              last_name:
                row.surname.charAt(0).toUpperCase() +
                row.surname.toLowerCase().slice(1),
              email: row.email.toLowerCase(),
              phone: '',
              mobile: '',
              company_id: 0,
              company_name: row.organisation,
              job_title: row.job,
            },
            { headers },
          ),
        ),
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('failed to add to fershworks with error');
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
  const handleRegister = async () => {
    setSearchTerm('');
    const responseData = await axios.post(
      `${baseUrl}/user-service/register-interest`,
      rows.map((row) => ({
        first_name:
          row.firstname.charAt(0).toUpperCase() +
          row.firstname.toLowerCase().slice(1),
        surname:
          row.surname.charAt(0).toUpperCase() +
          row.surname.toLowerCase().slice(1),
        email: row.email.toLowerCase(),
        job: row.job,
        organisation: row.organisation,
        other: row.other,
        date_requested: new Date(),
        requested_by: uploadedBy,
        requested_by_email: uploadedByEmail,
        requested_by_job_title: uploadedByJob,
        crm_code: organisations
          .filter((o) => o.name === row.organisation)
          .map((o) => o.crm_code)
          .join(),
        crm_name: organisations
          .filter((o) => o.name === row.organisation)
          .map((o) => o.crm_name)
          .join(),
        applications_subscription: organisations
          .filter((o) => o.name === row.organisation)
          .map((o) => o.applications_subscription)
          .join(),
        lifecycle_stage: organisations
          .filter((o) => o.name === row.organisation)
          .map((o) => o.lifecycle_stage)
          .join(),
        lifecycle_status: organisations
          .filter((o) => o.name === row.organisation)
          .map((o) => o.lifecycle_status)
          .join(),
        subscription_status: organisations
          .filter((o) => o.name === row.organisation)
          .map((o) => o.subscription_status)
          .join(),
        subscription_types: organisations
          .filter((o) => o.name === row.organisation)
          .map((o) => o.subscription_types)
          .join(),
        approval_granted: false,
      })),
      { headers },
    );
    handleRegisterFreshworksIndividual(rows);
    setDisplaySuccessMessage(responseData.data?.response.message === 'success');
    setDisplayFailureMessage(
      !(responseData.data?.response.message === 'success'),
    );
    if (responseData.data?.response.message === 'success') {
      setMessage(
        <div>
          {' '}
          The following users have had their interest registered:
          <br />
          <ul>
            {rows.map((r) => (
              <li>{r.email}</li>
            ))}
          </ul>
        </div>,
      );
    } else {
      setMessage(
        <div>
          Sign up failed. Please try again or contact support@adviseinc.co.uk.
        </div>,
      );
    }
  };

  const handleValidate = () => {
    setSearchTerm('');
    setDisplaySuccessMessage(false);
    setDisplayFailureMessage(false);

    // check email validity
    const formatErrors = rows
      .filter((row) => !validEmail.test(row.email))
      .map((row) => ({ id: row.id, error: validEmailHelp }));

    // check for duplicate emails
    const duplicates = rows
      .map((e) => e.email)
      .map((e, i, final) => final.indexOf(e) !== i && i)
      .filter((obj) => rows[obj])
      .map((e) => rows[e].email);

    const duplicationErrors = rows
      .filter((row) => duplicates.length > 0 && duplicates.includes(row.email))
      .map((row) => ({
        id: row.id,
        error: 'Email must be unique.',
      }));

    setErrors([].concat([...formatErrors], [...duplicationErrors]));
    setValidated(true);
  };

  // Create a filtered list based on the search term
  let searchedRows = rows;
  if (searchTerm.length > 0) {
    searchedRows = searchedRows.filter((r) =>
      searchTerm.split(' ').every((term) =>
        `${r.firstname} ${r.surname}`
          .toLowerCase()
          .replace(/[^0-9a-z ]/gi, '')
          .includes(term.split('#').join('')),
      ),
    );
  }

  return rows.length > 0 ? (
    <div className="rounded-md">
      <div className="flex justify-between items-center py-4 bg-white">
        <input
          type="text"
          id="table-search-users"
          className="appearance-none block w-full py-2 px-4 leading-tight rounded-md border border-platform-primary-grey-200 bg-white font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0"
          placeholder="Search for users"
          value={searchTerm}
          onChange={(e) =>
            setSearchTerm(
              e.target.value.toLowerCase().replace(/[^#\da-z ]/gi, ''),
            )
          }
        />
        <div className="py-2">
          <button
            type="button"
            onClick={() => handleAddRow()}
            className="appearance-none block w-full px-3 py-1 leading-tight rounded-md border bg-white border-platform-primary-orange-800 border-2 rounded-md text-platform-primary-orange-800 font-bold hover:bg-platform-primary-orange-600 focus:bg-platform-primary-orange-800 hover:text-platform-ainc-grey-200 focus:text-white disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800">
            Add row
          </button>
        </div>
      </div>
      <div className="h-max-screen">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                Name
              </th>
              <th scope="col" className="py-3 px-6">
                Job Title
              </th>
              <th scope="col" className="py-3 px-6">
                Organisation
              </th>
              <th scope="col" className="py-3 px-6">
                Options
              </th>
            </tr>
          </thead>
          <tbody>
            {searchedRows.map((row, idx) => (
              <>
                <tr className="bg-white 'border-b' hover:bg-gray-50">
                  <th
                    scope="row"
                    className="flex items-center py-4 px-6 text-gray-900 whitespace-nowrap dark:text-white">
                    <div className="pl-3">
                      <div className="text-base font-semibold">
                        {`${row.firstname} ${row.surname}`}
                      </div>
                      <div className="font-normal text-gray-500">
                        {row.email}
                      </div>
                    </div>
                  </th>
                  <td className="py-4 px-6">{row.job}</td>
                  <td className="py-4 px-6">
                    {row.organisation !== 'OTHER'
                      ? row.organisation
                      : row.other}
                  </td>
                  <td className="flex flex-col justify-center py-4 px-6">
                    <button
                      type="button"
                      data-modal-toggle="defaultModal"
                      className="flex justify-center items-center bg-white border-platform-cta-error-800 border-2 rounded-md p-2 min-w-full text-platform-cta-error-800 font-bold hover:bg-platform-cta-error-600 focus:bg-platform-cta-error-800 hover:text-platform-cta-error-400 focus:text-white"
                      onClick={() => handleEditRow(row)}>
                      <FiEdit2 />
                    </button>
                    <button
                      type="button"
                      className="flex justify-center items-center bg-white border-platform-cta-error-800 border-2 rounded-md p-2 min-w-full text-platform-cta-error-800 font-bold hover:bg-platform-cta-error-600 focus:bg-platform-cta-error-800 hover:text-platform-cta-error-400 focus:text-white"
                      onClick={() => handleDeleteRow(idx)}>
                      <FiXCircle />
                    </button>
                  </td>
                </tr>
                {errors.map((e) => e.id).includes(row.id) ? (
                  <tr className="bg-white border-b">
                    <div className="flex flex-col">
                      {errors
                        .filter((e) => e.id === row.id)
                        .map((e) => e.error)
                        .map((error) => (
                          <td
                            colSpan="4"
                            className="px-6 text-platform-cta-error-800 font-bold">
                            {error}
                          </td>
                        ))}
                    </div>
                  </tr>
                ) : null}
              </>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between">
        <div className="py-2">
          <button
            type="button"
            onClick={() => {
              handleValidate();
            }}
            className="appearance-none block w-full px-3 py-1 leading-tight rounded-md border bg-white border-platform-primary-orange-800 border-2 rounded-md text-platform-primary-orange-800 font-bold hover:bg-platform-primary-orange-600 focus:bg-platform-primary-orange-800 hover:text-platform-ainc-grey-200 focus:text-white disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800">
            Validate Users
          </button>
        </div>
        <div className="py-2">
          <button
            type="button"
            onClick={() => handleDeleteAll()}
            className="appearance-none block w-full px-3 py-1 leading-tight rounded-md border bg-white border-platform-primary-orange-800 border-2 rounded-md text-platform-primary-orange-800 font-bold hover:bg-platform-primary-orange-600 focus:bg-platform-primary-orange-800 hover:text-platform-ainc-grey-200 focus:text-white disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800">
            Delete All
          </button>
        </div>
      </div>
      <div>
        {validated && errors.length === 0 ? (
          <div
            className="p-4 mb-4 text-sm rounded-md bg-platform-cta-success-400"
            role="alert">
            <span className="font-medium">
              Validation successful. Please click Sign Up Users below to
              complete the registration process.
            </span>
          </div>
        ) : validated && errors.length > 0 ? (
          <div
            className="p-4 mb-4 text-sm rounded-md bg-platform-cta-error-200"
            role="alert">
            <span className="font-medium">
              Validation failed. Please fix highlighted issues and try again.
            </span>
          </div>
        ) : null}
      </div>
      <div className="py-2">
        <button
          type="button"
          onClick={handleRegister}
          disabled={!(validated && errors.length === 0)}
          className="appearance-none block w-full px-3 py-1 leading-tight rounded-md border bg-white border-platform-primary-orange-800 border-2 rounded-md text-platform-primary-orange-800 font-bold hover:bg-platform-primary-orange-600 focus:bg-platform-primary-orange-800 hover:text-platform-ainc-grey-200 focus:text-white disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800">
          Sign Up Users
        </button>
      </div>
      {rows.length > 0 && rowToEdit !== null ? (
        <EditRowModal
          showModal={showModal}
          setShowModal={setShowModal}
          rows={rows}
          setRows={setRows}
          rowToEdit={rowToEdit}
          setRowToEdit={setRowToEdit}
        />
      ) : null}
      {displaySuccessMessage ? (
        <div
          className="p-4 mb-4 text-sm rounded-md green-700 bg-platform-cta-success-400"
          role="alert">
          <span className="font-medium">Success!</span> {message}
        </div>
      ) : displayFailureMessage ? (
        <div
          className="flex p-4 mb-4 text-sm rounded-md red-700 bg-platform-cta-error-400"
          role="alert">
          <span className="font-medium">Failure!</span> {message}
        </div>
      ) : null}
    </div>
  ) : null;
}

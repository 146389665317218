export const baseUrl =
  process.env.NODE_ENV === 'asdf'
    ? 'http://localhost:3001'
    : process.env.REACT_APP_API_BASE_URL;
export const headers = {
  'Content-Type': 'application/json',
  authorization: 'allow',
  'Access-Control-Allow-Origin': 'True',
};

export const validEmail =
  /^[a-zA-Z0-9-\._:$!%']+@[a-zA-Z0-9\.-]*(?:nhs|adviseinc|hscni|nhs.scot|gardiner|htepg|pwc|crowncommercial.gov)\.[a-zA-Z\\.]{2,5}$/; // eslint-disable-line
export const validEmailHelp =
  'Email must be a valid NHS or HSCNI email in the form xxx@xxx.xxx.';

export const organisations = [
  {
    id: 1,
    name: 'AIREDALE NHS FOUNDATION TRUST | RCF',
    crm_code: '17002920826',
    crm_name: 'Airedale NHS Foundation Trust | RCF',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 2,
    name: "ALDER HEY CHILDREN'S NHS FOUNDATION TRUST | RBS",
    crm_code: '17002920827',
    crm_name: "Alder Hey Children's NHS Foundation Trust | RBS",
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 3,
    name: 'ANEURIN BEVAN UNIVERSITY LHB | 7A6',
    crm_code: '17002920828',
    crm_name: 'Aneurin Bevan University LHB | 7A6',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 4,
    name: "ASHFORD AND ST PETER'S HOSPITALS NHS FOUNDATION TRUST | RTK",
    crm_code: '17002920829',
    crm_name: "Ashford and St Peter's Hospitals NHS Foundation Trust | RTK",
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 5,
    name: 'AVON AND WILTSHIRE MENTAL HEALTH PARTNERSHIP NHS TRUST | RVN',
    crm_code: '17002920830',
    crm_name: 'Avon and Wiltshire Mental Health Partnership NHS Trust | RVN',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 6,
    name: 'BARKING, HAVERING AND REDBRIDGE UNIVERSITY HOSPITALS NHS TRUST | RF4',
    crm_code: '17002920831',
    crm_name:
      'Barking, Havering and Redbridge University Hospitals NHS Trust | RF4',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 7,
    name: 'BARNET, ENFIELD AND HARINGEY MENTAL HEALTH NHS TRUST | RRP',
    crm_code: '17002920832',
    crm_name: 'Barnet, Enfield and Haringey Mental Health NHS Trust | RRP',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 8,
    name: 'BARNSLEY HOSPITAL NHS FOUNDATION TRUST | RFF',
    crm_code: '17002920833',
    crm_name: 'Barnsley Hospital NHS Foundation Trust | RFF',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 9,
    name: 'BARTS HEALTH NHS TRUST | R1H',
    crm_code: '17002920834',
    crm_name: 'Barts Health NHS Trust | R1H',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 10,
    name: 'BEDFORDSHIRE HOSPITALS NHS FOUNDATION TRUST | RC9',
    crm_code: '17002920913',
    crm_name: 'Bedfordshire Hospitals NHS Foundation Trust | RC9',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 11,
    name: 'BELFAST HEALTH & SOCIAL CARE TRUST | ZT1',
    crm_code: '17002921170',
    crm_name: 'Belfast Health & Social Care Trust | ZT1',
    applications_subscription: 'Price Benchmarking (PB), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 12,
    name: 'BERKSHIRE HEALTHCARE NHS FOUNDATION TRUST | RWX',
    crm_code: '17002920835',
    crm_name: 'Berkshire Healthcare NHS Foundation Trust | RWX',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 13,
    name: 'BETSI CADWALADR UNIVERSITY LHB | 7A1',
    crm_code: '17002920836',
    crm_name: 'Betsi Cadwaladr University LHB | 7A1',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 14,
    name: 'BIRMINGHAM AND SOLIHULL MENTAL HEALTH NHS FOUNDATION TRUST | RXT',
    crm_code: '17002920837',
    crm_name:
      'Birmingham and Solihull Mental Health NHS Foundation Trust | RXT',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 15,
    name: 'BIRMINGHAM COMMUNITY HEALTHCARE NHS FOUNDATION TRUST | RYW',
    crm_code: '17002923059',
    crm_name: 'Birmingham Community Healthcare NHS Foundation Trust | RYW',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 16,
    name: "BIRMINGHAM WOMEN'S AND CHILDREN'S NHS FOUNDATION TRUST | RQ3",
    crm_code: '17002920838',
    crm_name: "Birmingham Women's and Children's NHS Foundation Trust | RQ3",
    applications_subscription:
      'Procurement Dashboard+ (PD+), Platform, Catalogue Check (Lite), Workplan',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 17,
    name: 'BLACKPOOL TEACHING HOSPITALS NHS FOUNDATION TRUST | RXL',
    crm_code: '17002920840',
    crm_name: 'Blackpool Teaching Hospitals NHS Foundation Trust | RXL',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 18,
    name: 'BOLTON NHS FOUNDATION TRUST | RMC',
    crm_code: '17002920841',
    crm_name: 'Bolton NHS Foundation Trust | RMC',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 19,
    name: 'BRADFORD TEACHING HOSPITALS NHS FOUNDATION TRUST | RAE',
    crm_code: '17002920843',
    crm_name: 'Bradford Teaching Hospitals NHS Foundation Trust | RAE',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 20,
    name: 'BRIDGEWATER COMMUNITY HEALTHCARE NHS FOUNDATION TRUST | RY2',
    crm_code: '17002923060',
    crm_name: 'Bridgewater Community Healthcare NHS Foundation Trust | RY2',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 21,
    name: 'BRIGHTON AND SUSSEX UNIVERSITY HOSPITALS NHS TRUST | RXH',
    crm_code: '17002920844',
    crm_name: 'Brighton and Sussex University Hospitals NHS Trust | RXH',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 22,
    name: 'BUCKINGHAMSHIRE HEALTHCARE NHS TRUST | RXQ',
    crm_code: '17002920845',
    crm_name: 'Buckinghamshire Healthcare NHS Trust | RXQ',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 23,
    name: 'CALDERDALE AND HUDDERSFIELD NHS FOUNDATION TRUST | RWY',
    crm_code: '17002920846',
    crm_name: 'Calderdale and Huddersfield NHS Foundation Trust | RWY',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 24,
    name: 'CAMBRIDGE UNIVERSITY HOSPITALS NHS FOUNDATION TRUST | RGT',
    crm_code: '17002920847',
    crm_name: 'Cambridge University Hospitals NHS Foundation Trust | RGT',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 25,
    name: 'CAMBRIDGESHIRE AND PETERBOROUGH NHS FOUNDATION TRUST | RT1',
    crm_code: '17002920848',
    crm_name: 'Cambridgeshire and Peterborough NHS Foundation Trust | RT1',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 26,
    name: 'CAMBRIDGESHIRE COMMUNITY SERVICES NHS TRUST | RYV',
    crm_code: '17002923061',
    crm_name: 'Cambridgeshire Community Services NHS Trust | RYV',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 27,
    name: 'CARDIFF AND VALE UNIVERSITY LHB | 7A4',
    crm_code: '17002920850',
    crm_name: 'Cardiff and Vale University LHB | 7A4',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 28,
    name: 'CENTRAL AND NORTH WEST LONDON NHS FOUNDATION TRUST | RV3',
    crm_code: '17002920851',
    crm_name: 'Central and North West London NHS Foundation Trust | RV3',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 29,
    name: 'CENTRAL LONDON COMMUNITY HEALTHCARE NHS TRUST | RYX',
    crm_code: '17002923062',
    crm_name: 'Central London Community Healthcare NHS Trust | RYX',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 30,
    name: 'CHELSEA AND WESTMINSTER HOSPITAL NHS FOUNDATION TRUST | RQM',
    crm_code: '17002920852',
    crm_name: 'Chelsea and Westminster Hospital NHS Foundation Trust | RQM',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 31,
    name: 'CHESHIRE AND WIRRAL PARTNERSHIP NHS FOUNDATION TRUST | RXA',
    crm_code: '17002920853',
    crm_name: 'Cheshire and Wirral Partnership NHS Foundation Trust | RXA',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 32,
    name: 'CHESTERFIELD ROYAL HOSPITAL NHS FOUNDATION TRUST | RFS',
    crm_code: '17002920854',
    crm_name: 'Chesterfield Royal Hospital NHS Foundation Trust | RFS',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 33,
    name: 'CORNWALL PARTNERSHIP NHS FOUNDATION TRUST | RJ8',
    crm_code: '17002920856',
    crm_name: 'Cornwall Partnership NHS Foundation Trust | RJ8',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 34,
    name: 'COUNTESS OF CHESTER HOSPITAL NHS FOUNDATION TRUST | RJR',
    crm_code: '17002920857',
    crm_name: 'Countess of Chester Hospital NHS Foundation Trust | RJR',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 35,
    name: 'COUNTY DURHAM AND DARLINGTON NHS FOUNDATION TRUST | RXP',
    crm_code: '17002920858',
    crm_name: 'County Durham and Darlington NHS Foundation Trust | RXP',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 36,
    name: 'COVENTRY AND WARWICKSHIRE PARTNERSHIP NHS TRUST | RYG',
    crm_code: '17002920859',
    crm_name: 'Coventry and Warwickshire Partnership NHS Trust | RYG',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 37,
    name: 'CROYDON HEALTH SERVICES NHS TRUST | RJ6',
    crm_code: '17002920860',
    crm_name: 'Croydon Health Services NHS Trust | RJ6',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 38,
    name: 'CUMBRIA, NORTHUMBERLAND, TYNE AND WEAR NHS FOUNDATION TRUST | RX4',
    crm_code: '17002920934',
    crm_name:
      'Cumbria, Northumberland, Tyne and Wear NHS Foundation Trust | RX4',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 39,
    name: 'CWM TAF MORGANNWG UNIVERSITY LHB | 7A5',
    crm_code: '17002920862',
    crm_name: 'Cwm Taf Morgannwg University LHB | 7A5',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 40,
    name: 'DARTFORD AND GRAVESHAM NHS TRUST | RN7',
    crm_code: '17002920863',
    crm_name: 'Dartford and Gravesham NHS Trust | RN7',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 41,
    name: 'DERBYSHIRE COMMUNITY HEALTH SERVICES NHS FOUNDATION TRUST | RY8',
    crm_code: '17002923063',
    crm_name: 'Derbyshire Community Health Services NHS Foundation Trust | RY8',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 42,
    name: 'DERBYSHIRE HEALTHCARE NHS FOUNDATION TRUST | RXM',
    crm_code: '17002920865',
    crm_name: 'Derbyshire Healthcare NHS Foundation Trust | RXM',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 43,
    name: 'DEVON PARTNERSHIP NHS TRUST | RWV',
    crm_code: '17002920866',
    crm_name: 'Devon Partnership NHS Trust | RWV',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 44,
    name: 'DONCASTER AND BASSETLAW TEACHING HOSPITALS NHS FOUNDATION TRUST | RP5',
    crm_code: '17002920867',
    crm_name:
      'Doncaster and Bassetlaw Teaching Hospitals NHS Foundation Trust | RP5',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 45,
    name: 'DORSET COUNTY HOSPITAL NHS FOUNDATION TRUST | RBD',
    crm_code: '17002920868',
    crm_name: 'Dorset County Hospital NHS Foundation Trust | RBD',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 46,
    name: 'DORSET HEALTHCARE UNIVERSITY NHS FOUNDATION TRUST | RDY',
    crm_code: '17002920869',
    crm_name: 'Dorset Healthcare University NHS Foundation Trust | RDY',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 47,
    name: 'DUDLEY INTEGRATED HEALTH AND CARE NHS TRUST | RYK',
    crm_code: '17002920870',
    crm_name: 'Dudley and Walsall Mental Health Partnership NHS Trust | RYK',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 48,
    name: 'EAST AND NORTH HERTFORDSHIRE NHS TRUST | RWH',
    crm_code: '17002920871',
    crm_name: 'East and North Hertfordshire NHS Trust | RWH',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 49,
    name: 'EAST CHESHIRE NHS TRUST | RJN',
    crm_code: '17002920872',
    crm_name: 'East Cheshire NHS Trust | RJN',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 50,
    name: 'EAST KENT HOSPITALS UNIVERSITY NHS FOUNDATION TRUST | RVV',
    crm_code: '17002920873',
    crm_name: 'East Kent Hospitals University NHS Foundation Trust | RVV',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 51,
    name: 'EAST LANCASHIRE HOSPITALS NHS TRUST | RXR',
    crm_code: '17002920874',
    crm_name: 'East Lancashire Hospitals NHS Trust | RXR',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 52,
    name: 'EAST LONDON NHS FOUNDATION TRUST | RWK',
    crm_code: '17002920875',
    crm_name: 'East London NHS Foundation Trust | RWK',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 53,
    name: 'EAST MIDLANDS AMBULANCE SERVICE NHS TRUST | RX9',
    crm_code: '17002920876',
    crm_name: 'East Midlands Ambulance Service NHS Trust | RX9',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 54,
    name: 'EAST OF ENGLAND AMBULANCE SERVICE NHS TRUST | RYC',
    crm_code: '17002920877',
    crm_name: 'East of England Ambulance Service NHS Trust | RYC',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 55,
    name: 'EAST SUFFOLK AND NORTH ESSEX NHS FOUNDATION TRUST | RDE',
    crm_code: '17002920855',
    crm_name: 'East Suffolk and North Essex NHS Foundation Trust | RDE',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 56,
    name: 'EAST SUSSEX HEALTHCARE NHS TRUST | RXC',
    crm_code: '17002920878',
    crm_name: 'East Sussex Healthcare NHS Trust | RXC',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 57,
    name: 'EPSOM AND ST HELIER UNIVERSITY HOSPITALS NHS TRUST | RVR',
    crm_code: '17002920879',
    crm_name: 'Epsom and St Helier University Hospitals NHS Trust | RVR',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 58,
    name: 'ESSEX PARTNERSHIP UNIVERSITY NHS FOUNDATION TRUST | R1L',
    crm_code: '17002920821',
    crm_name: 'Essex Partnership University NHS Foundation Trust | R1L',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 59,
    name: 'FRIMLEY HEALTH NHS FOUNDATION TRUST | RDU',
    crm_code: '17002920880',
    crm_name: 'Frimley Health NHS Foundation Trust | RDU',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 60,
    name: 'GATESHEAD HEALTH NHS FOUNDATION TRUST | RR7',
    crm_code: '17002920881',
    crm_name: 'Gateshead Health NHS Foundation Trust | RR7',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 61,
    name: 'GEORGE ELIOT HOSPITAL NHS TRUST | RLT',
    crm_code: '17002920882',
    crm_name: 'George Eliot Hospital NHS Trust | RLT',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 62,
    name: 'GLOUCESTERSHIRE HEALTH AND CARE NHS FOUNDATION TRUST | RTQ',
    crm_code: '17002920824',
    crm_name: 'Gloucestershire Health and Care NHS Foundation Trust | RTQ',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 63,
    name: 'GLOUCESTERSHIRE HOSPITALS NHS FOUNDATION TRUST | RTE',
    crm_code: '17002920883',
    crm_name: 'Gloucestershire Hospitals NHS Foundation Trust | RTE',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 64,
    name: 'GREAT ORMOND STREET HOSPITAL FOR CHILDREN NHS FOUNDATION TRUST | RP4',
    crm_code: '17002920884',
    crm_name:
      'Great Ormond Street Hospital for Children NHS Foundation Trust | RP4',
    applications_subscription: '',
    lifecycle_stage: 'Expired',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 65,
    name: 'GREAT WESTERN HOSPITALS NHS FOUNDATION TRUST | RN3',
    crm_code: '17002920885',
    crm_name: 'Great Western Hospitals NHS Foundation Trust | RN3',
    applications_subscription:
      'Procurement Dashboard+ (PD+), Platform, Catalogue Assign (Medium)',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 66,
    name: 'GREATER MANCHESTER MENTAL HEALTH NHS FOUNDATION TRUST | RXV',
    crm_code: '17002920886',
    crm_name: 'Greater Manchester Mental Health NHS Foundation Trust | RXV',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 67,
    name: "GUY'S AND ST THOMAS' NHS FOUNDATION TRUST | RJ1",
    crm_code: '17002920887',
    crm_name: "Guy's and St Thomas' NHS Foundation Trust | RJ1",
    applications_subscription: '',
    lifecycle_stage: 'Expired',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 68,
    name: 'HAMPSHIRE HOSPITALS NHS FOUNDATION TRUST | RN5',
    crm_code: '17002920888',
    crm_name: 'Hampshire Hospitals NHS Foundation Trust | RN5',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 69,
    name: 'HARROGATE AND DISTRICT NHS FOUNDATION TRUST | RCD',
    crm_code: '17002920889',
    crm_name: 'Harrogate and District NHS Foundation Trust | RCD',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 70,
    name: 'HEALTH EDUCATION AND IMPROVEMENT WALES | 7A9',
    crm_code: '17002921179',
    crm_name: 'Health Education and Improvement Wales | 7A9',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 71,
    name: 'HEREFORDSHIRE AND WORCESTERSHIRE HEALTH AND CARE NHS TRUST | R1A',
    crm_code: '17002923074',
    crm_name: 'Worcestershire Health and Care NHS Trust | R1A',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 72,
    name: 'HERTFORDSHIRE COMMUNITY NHS TRUST | RY4',
    crm_code: '17002923064',
    crm_name: 'Hertfordshire Community NHS Trust | RY4',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 73,
    name: 'HERTFORDSHIRE PARTNERSHIP UNIVERSITY NHS FOUNDATION TRUST | RWR',
    crm_code: '17002920890',
    crm_name: 'Hertfordshire Partnership University NHS Foundation Trust | RWR',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 74,
    name: 'HOMERTON HEALTHCARE NHS FOUNDATION TRUST | RQX',
    crm_code: '17002920891',
    crm_name: 'Homerton University Hospital NHS Foundation Trust | RQX',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 75,
    name: 'HOUNSLOW AND RICHMOND COMMUNITY HEALTHCARE NHS TRUST | RY9',
    crm_code: '17002923065',
    crm_name: 'Hounslow and Richmond Community Healthcare NHS Trust | RY9',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 76,
    name: 'HULL UNIVERSITY TEACHING HOSPITALS NHS TRUST | RWA',
    crm_code: '17002920892',
    crm_name: 'Hull University Teaching Hospitals NHS Trust | RWA',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 77,
    name: 'HUMBER TEACHING NHS FOUNDATION TRUST | RV9',
    crm_code: '17002920893',
    crm_name: 'Humber NHS Foundation Trust | RV9',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 78,
    name: 'HYWEL DDA UNIVERSITY LHB | 7A2',
    crm_code: '17002920894',
    crm_name: 'Hywel Dda University LHB | 7A2',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 79,
    name: 'IMPERIAL COLLEGE HEALTHCARE NHS TRUST | RYJ',
    crm_code: '17002920895',
    crm_name: 'Imperial College Healthcare NHS Trust | RYJ',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 80,
    name: 'ISLE OF WIGHT NHS TRUST | R1F',
    crm_code: '17002920896',
    crm_name: 'Isle of Wight NHS Trust | R1F',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 81,
    name: 'JAMES PAGET UNIVERSITY HOSPITALS NHS FOUNDATION TRUST | RGP',
    crm_code: '17002920897',
    crm_name: 'James Paget University Hospitals NHS Foundation Trust | RGP',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 82,
    name: 'KENT AND MEDWAY NHS AND SOCIAL CARE PARTNERSHIP TRUST | RXY',
    crm_code: '17002920898',
    crm_name: 'Kent and Medway NHS and Social Care Partnership Trust | RXY',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 83,
    name: 'KENT COMMUNITY HEALTH NHS FOUNDATION TRUST | RYY',
    crm_code: '17002923066',
    crm_name: 'Kent Community Health NHS Foundation Trust | RYY',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 84,
    name: 'KETTERING GENERAL HOSPITAL NHS FOUNDATION TRUST | RNQ',
    crm_code: '17002920899',
    crm_name: 'Kettering General Hospital NHS Foundation Trust | RNQ',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 85,
    name: "KING'S COLLEGE HOSPITAL NHS FOUNDATION TRUST | RJZ",
    crm_code: '17002920900',
    crm_name: "King's College Hospital NHS Foundation Trust | RJZ",
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 86,
    name: 'KINGSTON HOSPITAL NHS FOUNDATION TRUST | RAX',
    crm_code: '17002920901',
    crm_name: 'Kingston Hospital NHS Foundation Trust | RAX',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 87,
    name: 'LANCASHIRE & SOUTH CUMBRIA NHS FOUNDATION TRUST | RW5',
    crm_code: '17002920902',
    crm_name: 'Lancashire and South Cumbria NHS Foundation Trust | RW5',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 88,
    name: 'LANCASHIRE TEACHING HOSPITALS NHS FOUNDATION TRUST | RXN',
    crm_code: '17002920903',
    crm_name: 'Lancashire Teaching Hospitals NHS Foundation Trust | RXN',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 89,
    name: 'LEEDS AND YORK PARTNERSHIP NHS FOUNDATION TRUST | RGD',
    crm_code: '17002920904',
    crm_name: 'Leeds and York Partnership NHS Foundation Trust | RGD',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 90,
    name: 'LEEDS COMMUNITY HEALTHCARE NHS TRUST | RY6',
    crm_code: '17002923067',
    crm_name: 'Leeds Community Healthcare NHS Trust | RY6',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 91,
    name: 'LEEDS TEACHING HOSPITALS NHS TRUST | RR8',
    crm_code: '17002920905',
    crm_name: 'Leeds Teaching Hospitals NHS Trust | RR8',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 92,
    name: 'LEICESTERSHIRE PARTNERSHIP NHS TRUST | RT5',
    crm_code: '17002920906',
    crm_name: 'Leicestershire Partnership NHS Trust | RT5',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 93,
    name: 'LEWISHAM AND GREENWICH NHS TRUST | RJ2',
    crm_code: '17002920907',
    crm_name: 'Lewisham and Greenwich NHS Trust | RJ2',
    applications_subscription: '',
    lifecycle_stage: 'Expired',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 94,
    name: 'LINCOLNSHIRE COMMUNITY HEALTH SERVICES NHS TRUST | RY5',
    crm_code: '17002923068',
    crm_name: 'Lincolnshire Community Health Services NHS Trust | RY5',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 95,
    name: 'LINCOLNSHIRE PARTNERSHIP NHS FOUNDATION TRUST | RP7',
    crm_code: '17002920908',
    crm_name: 'Lincolnshire Partnership NHS Foundation Trust | RP7',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 96,
    name: 'LIVERPOOL HEART AND CHEST HOSPITAL NHS FOUNDATION TRUST | RBQ',
    crm_code: '17002920909',
    crm_name: 'Liverpool Heart and Chest Hospital NHS Foundation Trust | RBQ',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 97,
    name: 'LIVERPOOL UNIVERSITY HOSPITALS NHS FOUNDATION TRUST | REM',
    crm_code: '17002920955',
    crm_name: 'Liverpool University Hospitals NHS Foundation Trust | REM',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 98,
    name: "LIVERPOOL WOMEN'S NHS FOUNDATION TRUST | REP",
    crm_code: '17002920910',
    crm_name: "Liverpool Women's NHS Foundation Trust | REP",
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 99,
    name: 'LONDON AMBULANCE SERVICE NHS TRUST | RRU',
    crm_code: '17002920911',
    crm_name: 'London Ambulance Service NHS Trust | RRU',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 100,
    name: 'LONDON NORTH WEST UNIVERSITY HEALTHCARE NHS TRUST | R1K',
    crm_code: '17002920912',
    crm_name: 'London North West University Healthcare NHS Trust | R1K',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 101,
    name: 'MAIDSTONE AND TUNBRIDGE WELLS NHS TRUST | RWF',
    crm_code: '17002920914',
    crm_name: 'Maidstone and Tunbridge Wells NHS Trust | RWF',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 102,
    name: 'MANCHESTER UNIVERSITY NHS FOUNDATION TRUST | R0A',
    crm_code: '17002920820',
    crm_name: 'Manchester University NHS Foundation Trust | R0A',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 103,
    name: 'MEDWAY NHS FOUNDATION TRUST | RPA',
    crm_code: '17002920915',
    crm_name: 'Medway NHS Foundation Trust | RPA',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 104,
    name: 'MERSEY CARE NHS FOUNDATION TRUST | RW4',
    crm_code: '17002920916',
    crm_name: 'Mersey Care NHS Foundation Trust | RW4',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 105,
    name: 'MID AND SOUTH ESSEX NHS FOUNDATION TRUST | RAJ',
    crm_code: '17002920977',
    crm_name: 'Mid and South Essex NHS Foundation Trust | RAJ',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 106,
    name: 'MID CHESHIRE HOSPITALS NHS FOUNDATION TRUST | RBT',
    crm_code: '17002920917',
    crm_name: 'Mid Cheshire Hospitals NHS Foundation Trust | RBT',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 107,
    name: 'MID YORKSHIRE HOSPITALS NHS TRUST | RXF',
    crm_code: '17002920918',
    crm_name: 'Mid Yorkshire Hospitals NHS Trust | RXF',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 108,
    name: 'MIDLANDS PARTNERSHIP NHS FOUNDATION TRUST | RRE',
    crm_code: '17002920971',
    crm_name: 'Midlands Partnership NHS Foundation Trust | RRE',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 109,
    name: 'MILTON KEYNES UNIVERSITY HOSPITAL NHS FOUNDATION TRUST | RD8',
    crm_code: '17002920919',
    crm_name: 'Milton Keynes University Hospital NHS Foundation Trust | RD8',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 110,
    name: 'MOORFIELDS EYE HOSPITAL NHS FOUNDATION TRUST | RP6',
    crm_code: '17002920920',
    crm_name: 'Moorfields Eye Hospital NHS Foundation Trust | RP6',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 111,
    name: 'NHS GREATER GLASGOW & CLYDE | SG1',
    crm_code: '17002921160',
    crm_name: 'NHS Greater Glasgow and Clyde | SG1',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 112,
    name: 'NHS NATIONAL SERVICES SCOTLAND | SX1',
    crm_code: '17002921167',
    crm_name: 'NHS National Services Scotland | SX1',
    applications_subscription: 'Price Benchmarking (PB), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 113,
    name: 'NHS WALES SHARED SERVICES PARTNERSHIP (NWSSP) | WAL',
    crm_code: '17003020176',
    crm_name: 'NHS Wales Shared Services Partnership | WAL',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 114,
    name: 'NORFOLK AND NORWICH UNIVERSITY HOSPITALS NHS FOUNDATION TRUST | RM1',
    crm_code: '17002920921',
    crm_name:
      'Norfolk and Norwich University Hospitals NHS Foundation Trust | RM1',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 115,
    name: 'NORFOLK AND SUFFOLK NHS FOUNDATION TRUST | RMY',
    crm_code: '17002920922',
    crm_name: 'Norfolk and Suffolk NHS Foundation Trust | RMY',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 116,
    name: 'NORFOLK COMMUNITY HEALTH AND CARE NHS TRUST | RY3',
    crm_code: '17002923069',
    crm_name: 'Norfolk Community Health and Care NHS Trust | RY3',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 117,
    name: 'NORTH BRISTOL NHS TRUST | RVJ',
    crm_code: '17002920923',
    crm_name: 'North Bristol NHS Trust | RVJ',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 118,
    name: 'NORTH CUMBRIA INTEGRATED CARE NHS FOUNDATION TRUST | RNN',
    crm_code: '17002920861',
    crm_name: 'North Cumbria Integrated Care NHS Foundation Trust | RNN',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 119,
    name: 'NORTH EAST AMBULANCE SERVICE NHS FOUNDATION TRUST | RX6',
    crm_code: '17002920924',
    crm_name: 'North East Ambulance Service NHS Foundation Trust | RX6',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 120,
    name: 'NORTH EAST LONDON NHS FOUNDATION TRUST | RAT',
    crm_code: '17002920925',
    crm_name: 'North East London NHS Foundation Trust | RAT',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 121,
    name: 'NORTH MIDDLESEX UNIVERSITY HOSPITAL NHS TRUST | RAP',
    crm_code: '17002920926',
    crm_name: 'North Middlesex University Hospital NHS Trust | RAP',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 122,
    name: 'NORTH STAFFORDSHIRE COMBINED HEALTHCARE NHS TRUST | RLY',
    crm_code: '17002920927',
    crm_name: 'North Staffordshire Combined Healthcare NHS Trust | RLY',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 123,
    name: 'NORTH TEES AND HARTLEPOOL NHS FOUNDATION TRUST | RVW',
    crm_code: '17002920928',
    crm_name: 'North Tees and Hartlepool NHS Foundation Trust | RVW',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 124,
    name: 'NORTH WEST AMBULANCE SERVICE NHS TRUST | RX7',
    crm_code: '17002920929',
    crm_name: 'North West Ambulance Service NHS Trust | RX7',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 125,
    name: 'NORTH WEST ANGLIA NHS FOUNDATION TRUST | RGN',
    crm_code: '17002920822',
    crm_name: 'North West Anglia NHS Foundation Trust | RGN',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 126,
    name: 'NORTH WEST BOROUGHS HEALTHCARE NHS FOUNDATION TRUST | RTV',
    crm_code: '17002921049',
    crm_name: 'North West Boroughs Healthcare NHS Foundation Trust | RTV',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 127,
    name: 'NORTHAMPTON GENERAL HOSPITAL NHS TRUST | RNS',
    crm_code: '17002920930',
    crm_name: 'Northampton General Hospital NHS Trust | RNS',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 128,
    name: 'NORTHAMPTONSHIRE HEALTHCARE NHS FOUNDATION TRUST | RP1',
    crm_code: '17002920931',
    crm_name: 'Northamptonshire Healthcare NHS Foundation Trust | RP1',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 129,
    name: 'NORTHERN CARE ALLIANCE NHS FOUNDATION TRUST | RM3',
    crm_code: '17002920959',
    crm_name: 'Salford Royal NHS Foundation Trust | RM3',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 130,
    name: 'NORTHERN DEVON HEALTHCARE NHS TRUST | RBZ',
    crm_code: '17002920932',
    crm_name: 'Northern Devon Healthcare NHS Trust | RBZ',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 131,
    name: 'NORTHERN HEATH & SOCIAL CARE TRUST | ZT2',
    crm_code: '17002921171',
    crm_name: 'Northern Heath & Social Care Trust | ZT2',
    applications_subscription: 'Price Benchmarking (PB), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 132,
    name: 'NORTHERN LINCOLNSHIRE AND GOOLE NHS FOUNDATION TRUST | RJL',
    crm_code: '17002920933',
    crm_name: 'Northern Lincolnshire and Goole NHS Foundation Trust | RJL',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 133,
    name: 'NORTHUMBRIA HEALTHCARE NHS FOUNDATION TRUST | RTF',
    crm_code: '17002920935',
    crm_name: 'Northumbria Healthcare NHS Foundation Trust | RTF',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 134,
    name: 'NOTTINGHAM UNIVERSITY HOSPITALS NHS TRUST | RX1',
    crm_code: '17002920936',
    crm_name: 'Nottingham University Hospitals NHS Trust | RX1',
    applications_subscription:
      'Procurement Dashboard (PD), Platform, Catalogue Manage (Heavy)',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 135,
    name: 'NOTTINGHAMSHIRE HEALTHCARE NHS FOUNDATION TRUST | RHA',
    crm_code: '17002920937',
    crm_name: 'Nottinghamshire Healthcare NHS Foundation Trust | RHA',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 136,
    name: 'OTHER',
    crm_code: '',
    crm_name: 'Other',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 137,
    name: 'OXFORD HEALTH NHS FOUNDATION TRUST | RNU',
    crm_code: '17002920938',
    crm_name: 'Oxford Health NHS Foundation Trust | RNU',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 138,
    name: 'OXFORD UNIVERSITY HOSPITALS NHS FOUNDATION TRUST | RTH',
    crm_code: '17002920939',
    crm_name: 'Oxford University Hospitals NHS Foundation Trust | RTH',
    applications_subscription: '',
    lifecycle_stage: 'Expired',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 139,
    name: 'OXLEAS NHS FOUNDATION TRUST | RPG',
    crm_code: '17002920940',
    crm_name: 'Oxleas NHS Foundation Trust | RPG',
    applications_subscription: '',
    lifecycle_stage: 'Expired',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 140,
    name: 'PENNINE ACUTE HOSPITALS NHS TRUST | RW6',
    crm_code: '17002920942',
    crm_name: 'Pennine Acute Hospitals NHS Trust | RW6',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 141,
    name: 'PENNINE CARE NHS FOUNDATION TRUST | RT2',
    crm_code: '17002920943',
    crm_name: 'Pennine Care NHS Foundation Trust | RT2',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 142,
    name: 'POOLE HOSPITAL NHS FOUNDATION TRUST | RD3',
    crm_code: '17002921064',
    crm_name: 'Poole Hospital NHS Foundation Trust | RD3',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 143,
    name: 'PORTSMOUTH HOSPITALS UNIVERSITY NATIONAL HEALTH SERVICE TRUST | RHU',
    crm_code: '17002920945',
    crm_name: 'Portsmouth Hospitals University NHS Trust | RHU',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 144,
    name: 'POWYS TEACHING LHB | 7A7',
    crm_code: '17002920946',
    crm_name: 'Powys Teaching LHB | 7A7',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 145,
    name: 'PUBLIC HEALTH WALES NHS TRUST | RYT',
    crm_code: '17002920947',
    crm_name: 'Public Health Wales NHS Trust | RYT',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 146,
    name: 'QUEEN VICTORIA HOSPITAL NHS FOUNDATION TRUST | RPC',
    crm_code: '17002920948',
    crm_name: 'Queen Victoria Hospital NHS Foundation Trust | RPC',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 147,
    name: 'ROTHERHAM DONCASTER AND SOUTH HUMBER NHS FOUNDATION TRUST | RXE',
    crm_code: '17002920949',
    crm_name: 'Rotherham Doncaster and South Humber NHS Foundation Trust | RXE',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 148,
    name: 'ROYAL BERKSHIRE NHS FOUNDATION TRUST | RHW',
    crm_code: '17002920950',
    crm_name: 'Royal Berkshire NHS Foundation Trust | RHW',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 149,
    name: 'ROYAL BROMPTON & HAREFIELD NHS FOUNDATION TRUST | RT3',
    crm_code: '17002920951',
    crm_name: 'Royal Brompton & Harefield NHS Foundation Trust | RT3',
    applications_subscription: '',
    lifecycle_stage: 'Expired',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 150,
    name: 'ROYAL CORNWALL HOSPITALS NHS TRUST | REF',
    crm_code: '17002920952',
    crm_name: 'Royal Cornwall Hospitals NHS Trust | REF',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 151,
    name: 'ROYAL DEVON UNIVERSITY HEALTHCARE NHS FOUNDATION TRUST | RH8',
    crm_code: '17002920953',
    crm_name: 'Royal Devon and Exeter NHS Foundation Trust | RH8',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 152,
    name: 'ROYAL FREE LONDON NHS FOUNDATION TRUST | RAL',
    crm_code: '17002920954',
    crm_name: 'Royal Free London NHS Foundation Trust | RAL',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 153,
    name: 'ROYAL NATIONAL ORTHOPAEDIC HOSPITAL NHS TRUST | RAN',
    crm_code: '17002920956',
    crm_name: 'Royal National Orthopaedic Hospital NHS Trust | RAN',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 154,
    name: 'ROYAL PAPWORTH HOSPITAL NHS FOUNDATION TRUST | RGM',
    crm_code: '17002920941',
    crm_name: 'Royal Papworth Hospital NHS Foundation Trust | RGM',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 155,
    name: 'ROYAL SURREY COUNTY HOSPITAL NHS FOUNDATION TRUST | RA2',
    crm_code: '17002920957',
    crm_name: 'Royal Surrey County Hospital NHS Foundation Trust | RA2',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 156,
    name: 'ROYAL UNITED HOSPITALS BATH NHS FOUNDATION TRUST | RD1',
    crm_code: '17002920958',
    crm_name: 'Royal United Hospitals Bath NHS Foundation Trust | RD1',
    applications_subscription:
      'Procurement Dashboard+ (PD+), Platform, Catalogue Assign (Medium)',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 157,
    name: 'SALISBURY NHS FOUNDATION TRUST | RNZ',
    crm_code: '17002920960',
    crm_name: 'Salisbury NHS Foundation Trust | RNZ',
    applications_subscription:
      'Procurement Dashboard+ (PD+), Platform, Catalogue Assign (Medium)',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 158,
    name: 'SANDWELL AND WEST BIRMINGHAM HOSPITALS NHS TRUST | RXK',
    crm_code: '17002920961',
    crm_name: 'Sandwell and West Birmingham Hospitals NHS Trust | RXK',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 159,
    name: "SHEFFIELD CHILDREN'S NHS FOUNDATION TRUST | RCU",
    crm_code: '17002920962',
    crm_name: "Sheffield Children's NHS Foundation Trust | RCU",
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 160,
    name: 'SHEFFIELD TEACHING HOSPITALS NHS FOUNDATION TRUST | RHQ',
    crm_code: '17002920964',
    crm_name: 'Sheffield Teaching Hospitals NHS Foundation Trust | RHQ',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 161,
    name: 'SHERWOOD FOREST HOSPITALS NHS FOUNDATION TRUST | RK5',
    crm_code: '17002920965',
    crm_name: 'Sherwood Forest Hospitals NHS Foundation Trust | RK5',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 162,
    name: 'SHROPSHIRE COMMUNITY HEALTH NHS TRUST | R1D',
    crm_code: '17002923070',
    crm_name: 'Shropshire Community Health NHS Trust | R1D',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 163,
    name: 'SOLENT NHS TRUST | R1C',
    crm_code: '17002923071',
    crm_name: 'Solent NHS Trust | R1C',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 164,
    name: 'SOMERSET NHS FOUNDATION TRUST | RH5',
    crm_code: '17002920967',
    crm_name: 'Somerset NHS Foundation Trust | RH5',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 165,
    name: 'SOUTH CENTRAL AMBULANCE SERVICE NHS FOUNDATION TRUST | RYE',
    crm_code: '17002920968',
    crm_name: 'South Central Ambulance Service NHS Foundation Trust | RYE',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 166,
    name: 'SOUTH EAST COAST AMBULANCE SERVICE NHS FOUNDATION TRUST | RYD',
    crm_code: '17002920969',
    crm_name: 'South East Coast Ambulance Service NHS Foundation Trust | RYD',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 167,
    name: 'SOUTH EASTERN HEALTH & SOCIAL CARE | ZT4',
    crm_code: '17002921173',
    crm_name: 'South Eastern Health & Social Care | ZT4',
    applications_subscription: 'Price Benchmarking (PB), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 168,
    name: 'SOUTH LONDON AND MAUDSLEY NHS FOUNDATION TRUST | RV5',
    crm_code: '17002920970',
    crm_name: 'South London and Maudsley NHS Foundation Trust | RV5',
    applications_subscription: '',
    lifecycle_stage: 'Expired',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 169,
    name: 'SOUTH TEES HOSPITALS NHS FOUNDATION TRUST | RTR',
    crm_code: '17002920972',
    crm_name: 'South Tees Hospitals NHS Foundation Trust | RTR',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 170,
    name: 'SOUTH TYNESIDE AND SUNDERLAND NHS FOUNDATION TRUST | R0B',
    crm_code: '17002920819',
    crm_name: 'South Tyneside and Sunderland NHS Foundation Trust | R0B',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 171,
    name: 'SOUTH WARWICKSHIRE UNIVERSITY NHS FOUNDATION TRUST | RJC',
    crm_code: '17002920973',
    crm_name: 'South Warwickshire NHS Foundation Trust | RJC',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 172,
    name: "SOUTH WEST LONDON AND ST GEORGE'S MENTAL HEALTH NHS TRUST | RQY",
    crm_code: '17002920974',
    crm_name: "South West London and St George's Mental Health NHS Trust | RQY",
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 173,
    name: 'SOUTH WEST YORKSHIRE PARTNERSHIP NHS FOUNDATION TRUST | RXG',
    crm_code: '17002920975',
    crm_name: 'South West Yorkshire Partnership NHS Foundation Trust | RXG',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 174,
    name: 'SOUTH WESTERN AMBULANCE SERVICE NHS FOUNDATION TRUST | RYF',
    crm_code: '17002920976',
    crm_name: 'South Western Ambulance Service NHS Foundation Trust | RYF',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 175,
    name: 'SOUTHERN HEALTH & SOCIAL CARE TRUST | ZT3',
    crm_code: '17002921172',
    crm_name: 'Southern Health & Social Care Trust | ZT3',
    applications_subscription: 'Price Benchmarking (PB), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 176,
    name: 'SOUTHERN HEALTH NHS FOUNDATION TRUST | RW1',
    crm_code: '17002920978',
    crm_name: 'Southern Health NHS Foundation Trust | RW1',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 178,
    name: "ST GEORGE'S UNIVERSITY HOSPITALS NHS FOUNDATION TRUST | RJ7",
    crm_code: '17002920980',
    crm_name: "St George's University Hospitals NHS Foundation Trust | RJ7",
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 180,
    name: 'STOCKPORT NHS FOUNDATION TRUST | RWJ',
    crm_code: '17002920982',
    crm_name: 'Stockport NHS Foundation Trust | RWJ',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 181,
    name: 'SURREY AND BORDERS PARTNERSHIP NHS FOUNDATION TRUST | RXX',
    crm_code: '17002920983',
    crm_name: 'Surrey and Borders Partnership NHS Foundation Trust | RXX',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 182,
    name: 'SURREY AND SUSSEX HEALTHCARE NHS TRUST | RTP',
    crm_code: '17002920984',
    crm_name: 'Surrey and Sussex Healthcare NHS Trust | RTP',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 183,
    name: 'SUSSEX COMMUNITY NHS FOUNDATION TRUST | RDR',
    crm_code: '17002923072',
    crm_name: 'Sussex Community NHS Foundation Trust | RDR',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 184,
    name: 'SUSSEX PARTNERSHIP NHS FOUNDATION TRUST | RX2',
    crm_code: '17002920985',
    crm_name: 'Sussex Partnership NHS Foundation Trust | RX2',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 185,
    name: 'SWANSEA BAY UNIVERSITY LHB | 7A3',
    crm_code: '17002920825',
    crm_name: 'Swansea Bay University LHB | 7A3',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 186,
    name: 'TAMESIDE AND GLOSSOP INTEGRATED CARE NHS FOUNDATION TRUST | RMP',
    crm_code: '17002920986',
    crm_name: 'Tameside and Glossop Integrated Care NHS Foundation Trust | RMP',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 187,
    name: 'TAVISTOCK AND PORTMAN NHS FOUNDATION TRUST | RNK',
    crm_code: '17002920987',
    crm_name: 'Tavistock and Portman NHS Foundation Trust | RNK',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 188,
    name: 'TEES, ESK AND WEAR VALLEYS NHS FOUNDATION TRUST | RX3',
    crm_code: '17002920988',
    crm_name: 'Tees, Esk and Wear Valleys NHS Foundation Trust | RX3',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 189,
    name: 'THE CHRISTIE NHS FOUNDATION TRUST | RBV',
    crm_code: '17002920989',
    crm_name: 'The Christie NHS Foundation Trust | RBV',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 190,
    name: 'THE CLATTERBRIDGE CANCER CENTRE NHS FOUNDATION TRUST | REN',
    crm_code: '17002920990',
    crm_name: 'The Clatterbridge Cancer Centre NHS Foundation Trust | REN',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 191,
    name: 'THE DUDLEY GROUP NHS FOUNDATION TRUST | RNA',
    crm_code: '17002920992',
    crm_name: 'The Dudley Group NHS Foundation Trust | RNA',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 192,
    name: 'THE HILLINGDON HOSPITALS NHS FOUNDATION TRUST | RAS',
    crm_code: '17002920993',
    crm_name: 'The Hillingdon Hospitals NHS Foundation Trust | RAS',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 193,
    name: 'THE NEWCASTLE UPON TYNE HOSPITALS NHS FOUNDATION TRUST | RTD',
    crm_code: '17002920994',
    crm_name: 'The Newcastle Upon Tyne Hospitals NHS Foundation Trust | RTD',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 194,
    name: 'THE PRINCESS ALEXANDRA HOSPITAL NHS TRUST | RQW',
    crm_code: '17002920995',
    crm_name: 'The Princess Alexandra Hospital NHS Trust | RQW',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 195,
    name: "THE QUEEN ELIZABETH HOSPITAL, KING'S LYNN, NHS FOUNDATION TRUST | RCX",
    crm_code: '17002920996',
    crm_name:
      "The Queen Elizabeth Hospital, King's Lynn, NHS Foundation Trust | RCX",
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 196,
    name: 'THE ROBERT JONES AND AGNES HUNT ORTHOPAEDIC HOSPITAL NHS FOUNDATION TRUST | RL1',
    crm_code: '17002920997',
    crm_name:
      'The Robert Jones and Agnes Hunt Orthopaedic Hospital NHS Foundation Trust | RL1',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 197,
    name: 'THE ROTHERHAM NHS FOUNDATION TRUST | RFR',
    crm_code: '17002920998',
    crm_name: 'The Rotherham NHS Foundation Trust | RFR',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 198,
    name: 'THE ROYAL BOURNEMOUTH AND CHRISTCHURCH HOSPITALS NHS FOUNDATION TRUST | RDZ',
    crm_code: '17002921063',
    crm_name:
      'The Royal Bournemouth and Christchurch Hospitals NHS Foundation Trust | RDZ',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 199,
    name: 'THE ROYAL MARSDEN NHS FOUNDATION TRUST | RPY',
    crm_code: '17002920999',
    crm_name: 'The Royal Marsden NHS Foundation Trust | RPY',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 200,
    name: 'THE ROYAL ORTHOPAEDIC HOSPITAL NHS FOUNDATION TRUST | RRJ',
    crm_code: '17002921000',
    crm_name: 'The Royal Orthopaedic Hospital NHS Foundation Trust | RRJ',
    applications_subscription:
      'Procurement Dashboard+ (PD+), Platform, Catalogue Check (Lite), Workplan',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 201,
    name: 'THE ROYAL WOLVERHAMPTON NHS TRUST | RL4',
    crm_code: '17002921001',
    crm_name: 'The Royal Wolverhampton NHS Trust | RL4',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 202,
    name: 'THE SHREWSBURY AND TELFORD HOSPITAL NHS TRUST | RXW',
    crm_code: '17002920966',
    crm_name: 'Shrewsbury and Telford Hospital NHS Trust | RXW',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 203,
    name: 'THE WALTON CENTRE NHS FOUNDATION TRUST | RET',
    crm_code: '17002921002',
    crm_name: 'The Walton Centre NHS Foundation Trust | RET',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 204,
    name: 'TORBAY AND SOUTH DEVON NHS FOUNDATION TRUST | RA9',
    crm_code: '17002921004',
    crm_name: 'Torbay and South Devon NHS Foundation Trust | RA9',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 205,
    name: 'UNITED LINCOLNSHIRE HOSPITALS NHS TRUST | RWD',
    crm_code: '17002921005',
    crm_name: 'United Lincolnshire Hospitals NHS Trust | RWD',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 206,
    name: 'UNIVERSITY COLLEGE LONDON HOSPITALS NHS FOUNDATION TRUST | RRV',
    crm_code: '17002921006',
    crm_name: 'University College London Hospitals NHS Foundation Trust | RRV',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 207,
    name: 'UNIVERSITY HOSPITAL SOUTHAMPTON NHS FOUNDATION TRUST | RHM',
    crm_code: '17002921007',
    crm_name: 'University Hospital Southampton NHS Foundation Trust | RHM',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 208,
    name: 'UNIVERSITY HOSPITALS BIRMINGHAM NHS FOUNDATION TRUST | RRK',
    crm_code: '17002921008',
    crm_name: 'University Hospitals Birmingham NHS Foundation Trust | RRK',
    applications_subscription:
      'Procurement Dashboard+ (PD+), Platform, Catalogue Check (Lite), Workplan',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 209,
    name: 'UNIVERSITY HOSPITALS BRISTOL AND WESTON NHS FOUNDATION TRUST | RA7',
    crm_code: '17002921009',
    crm_name: 'University Hospitals Bristol NHS Foundation Trust | RA7',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 210,
    name: 'UNIVERSITY HOSPITALS COVENTRY AND WARWICKSHIRE NHS TRUST | RKB',
    crm_code: '17002921010',
    crm_name: 'University Hospitals Coventry and Warwickshire NHS Trust | RKB',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 211,
    name: 'UNIVERSITY HOSPITALS DORSET NHS FOUNDATION TRUST | R0D',
    crm_code: '17002920823',
    crm_name: 'University Hospitals Dorset NHS Foundation Trust | R0D',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 212,
    name: 'UNIVERSITY HOSPITALS OF DERBY AND BURTON NHS FOUNDATION TRUST | RTG',
    crm_code: '17002920864',
    crm_name:
      'University Hospitals of Derby and Burton NHS Foundation Trust | RTG',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 213,
    name: 'UNIVERSITY HOSPITALS OF LEICESTER NHS TRUST | RWE',
    crm_code: '17002921011',
    crm_name: 'University Hospitals of Leicester NHS Trust | RWE',
    applications_subscription: 'Procurement Dashboard+ (PD+), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 214,
    name: 'UNIVERSITY HOSPITALS OF MORECAMBE BAY NHS FOUNDATION TRUST | RTX',
    crm_code: '17002921012',
    crm_name:
      'University Hospitals of Morecambe Bay NHS Foundation Trust | RTX',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 215,
    name: 'UNIVERSITY HOSPITALS OF NORTH MIDLANDS NHS TRUST | RJE',
    crm_code: '17002921013',
    crm_name: 'University Hospitals of North Midlands NHS Trust | RJE',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 216,
    name: 'UNIVERSITY HOSPITALS PLYMOUTH NHS TRUST | RK9',
    crm_code: '17002920944',
    crm_name: 'University Hospitals Plymouth NHS Trust | RK9',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 217,
    name: 'UNIVERSITY HOSPITALS SUSSEX NHS FOUNDATION TRUST | RYR',
    crm_code: '17002921023',
    crm_name: 'Western Sussex Hospitals NHS Foundation Trust | RYR',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 218,
    name: 'VELINDRE NHS TRUST | RQF',
    crm_code: '17002921014',
    crm_name: 'Velindre NHS Trust | RQF',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 219,
    name: 'WALSALL HEALTHCARE NHS TRUST | RBK',
    crm_code: '17002921015',
    crm_name: 'Walsall Healthcare NHS Trust | RBK',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 220,
    name: 'WARRINGTON AND HALTON TEACHING HOSPITALS NHS FOUNDATION TRUST | RWW',
    crm_code: '17002921016',
    crm_name: 'Warrington and Halton Hospitals NHS Foundation Trust | RWW',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 221,
    name: 'WELSH AMBULANCE SERVICES NHS TRUST | RT4',
    crm_code: '17002921017',
    crm_name: 'Welsh Ambulance Services NHS Trust | RT4',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 222,
    name: 'WELSH BLOOD SERVICE | 7B1',
    crm_code: '17002921180',
    crm_name: 'Welsh Blood Service | 7B1',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 223,
    name: 'WELSH HEALTH SPECIALIST SERVICES COMMITTEE | 7A8',
    crm_code: '17002921018',
    crm_name: 'Welsh Health Specialist Services Committee | 7A8',
    applications_subscription: 'PD',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 224,
    name: 'WEST HERTFORDSHIRE TEACHING HOSPITALS NHS TRUST | RWG',
    crm_code: '17002921019',
    crm_name: 'West Hertfordshire Hospitals NHS Trust | RWG',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 225,
    name: 'WEST LONDON NHS TRUST | RKL',
    crm_code: '17002921020',
    crm_name: 'West London NHS Trust | RKL',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 226,
    name: 'WEST MIDLANDS AMBULANCE SERVICE UNIVERSITY NHS FOUNDATION TRUST | RYA',
    crm_code: '17002921021',
    crm_name: 'West Midlands Ambulance Service NHS Foundation Trust | RYA',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 227,
    name: 'WEST SUFFOLK NHS FOUNDATION TRUST | RGR',
    crm_code: '17002921022',
    crm_name: 'West Suffolk NHS Foundation Trust | RGR',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 228,
    name: 'WESTERN HEALTH & SOCIAL CARE TRUST | ZT5',
    crm_code: '17002921174',
    crm_name: 'Western Health & Social Care Trust | ZT5',
    applications_subscription: 'Price Benchmarking (PB), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 229,
    name: 'WHITTINGTON HEALTH NHS TRUST | RKE',
    crm_code: '17002921003',
    crm_name: 'The Whittington Hospital NHS Trust | RKE',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 230,
    name: 'WIRRAL COMMUNITY HEALTH AND CARE NHS FOUNDATION TRUST | RY7',
    crm_code: '17002923073',
    crm_name: 'Wirral Community Health and Care NHS Foundation Trust | RY7',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 231,
    name: 'WIRRAL UNIVERSITY TEACHING HOSPITAL NHS FOUNDATION TRUST | RBL',
    crm_code: '17002921024',
    crm_name: 'Wirral University Teaching Hospital NHS Foundation Trust | RBL',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 232,
    name: 'WORCESTERSHIRE ACUTE HOSPITALS NHS TRUST | RWP',
    crm_code: '17002921025',
    crm_name: 'Worcestershire Acute Hospitals NHS Trust | RWP',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 233,
    name: 'WRIGHTINGTON, WIGAN AND LEIGH NHS FOUNDATION TRUST | RRF',
    crm_code: '17002921026',
    crm_name: 'Wrightington, Wigan and Leigh NHS Foundation Trust | RRF',
    applications_subscription: 'Procurement Dashboard (PD), Platform',
    lifecycle_stage: 'Customer',
    lifecycle_status: 'Won',
    subscription_status: 'Subscribed',
    subscription_types:
      'Product updates, Newsletter, Conference & Events, Promotional, Application Service Update',
  },
  {
    id: 234,
    name: 'WYE VALLEY NHS TRUST | RLQ',
    crm_code: '17002921027',
    crm_name: 'Wye Valley NHS Trust | RLQ',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 235,
    name: 'SIMPLY SERVE | RA4',
    crm_code: '17005859793',
    crm_name: 'Simply Serve',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 236,
    name: 'YORK AND SCARBOROUGH TEACHING HOSPITALS NHS FOUNDATION TRUST | RCB',
    crm_code: '17002921029',
    crm_name: 'York Teaching Hospital NHS Foundation Trust | RCB',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 237,
    name: 'YORKSHIRE AMBULANCE SERVICE NHS TRUST | RX8',
    crm_code: '17002921030',
    crm_name: 'Yorkshire Ambulance Service NHS Trust | RX8',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 238,
    name: 'BUSINESS SERVICES ORGANISATION - PROCUREMENT AND LOGISTICS SERVICE (BSO PALS)',
    crm_code: '',
    crm_name:
      'Business Services Organisation - Procurement and Logistics Service (BSO PALS)',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 239,
    name: 'MERSEY AND WEST LANCASHIRE TEACHING HOSPITALS NHS TRUST | RBN',
    crm_code: '',
    crm_name: 'Mersey and West Lancashire Teaching Hospitals NHS Trust | RBN',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
  {
    id: 240,
    name: 'NHS Dorset ICB | QVV',
    crm_code: '17003019986',
    crm_name: 'NHS Dorset ICB | QVV',
    applications_subscription: '',
    lifecycle_stage: '',
    lifecycle_status: '',
    subscription_status: '',
    subscription_types: '',
  },
];

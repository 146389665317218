import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Reaptcha from 'reaptcha';
import env from 'react-dotenv';
import AdviseWhite from '../assets/AdviseInc_Logo_WHITE.png';

export default function Home() {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [captchaToken, setCaptchaToken] = useState(null);
  const [verified, setVerified] = useState(false);
  const captchaRef = useRef(null);

  const verify = () => {
    captchaRef.current.getResponse().then((res) => {
      setCaptchaToken(res);
    });
    setVerified(true);
  };

  return (
    <div className="font-main min-h-screen flex">
      <img
        src={AdviseWhite}
        className="absolute z-20 w-150 top-5 left-5"
        alt=""
      />
      <div className="bg-hero bg-no-repeat bg-cover bg-center flex-1 flex flex-col justify-center w-1/2 z-0">
        <div className="p-10 text-center font-extrabold text-6xl text-white tracking-tight leading-snug">
          Helping Healthcare <p className="italic">#MakeSense</p> of Spend Data
        </div>
      </div>

      <div className="flex-1 flex flex-col justify-center w-1/2 text-center bg-white">
        <div className="mx-auto w-full max-w-lg p-3 bg-white rounded-lg">
          <div className="pb-44">
            <h2 className="text-5xl font-bold text-platform-primary-grey-800">
              Welcome to the AdviseInc Platform
            </h2>
          </div>

          <div className="mt-12 items-center text-center">
            <div className="flex items-center justify-center mb-6">
              <Reaptcha
                sitekey={
                  process.env.NODE_ENV === 'development'
                    ? env.DEV_RECAPTCHA_SITE_KEY
                    : env.RECAPTCHA_SITE_KEY
                }
                ref={captchaRef}
                onVerify={verify}
              />
            </div>
            <button
              onClick={() => navigate(`/register`)}
              disabled={!verified}
              type="button"
              className="w-full px-3 py-3 bg-white border-platform-primary-orange-800 border-2 rounded-md p-2 min-w-full text-platform-primary-orange-800 font-bold hover:bg-platform-primary-orange-600 focus:bg-platform-primary-orange-800 hover:text-platform-ainc-grey-200 focus:text-white disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800">
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

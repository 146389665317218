import React from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { FiXCircle } from 'react-icons/fi';
import { organisations } from './lookups';

export default function EditRowModal({
  showModal,
  setShowModal,
  rows,
  setRows,
  rowToEdit,
  setRowToEdit,
}) {
  const handleSaveRow = (userDetails) => {
    // add edited row back to everything else in array, then sort by id
    setRows(
      [
        ...rows.filter((r) => r.id !== rowToEdit.id),
        {
          firstname: userDetails.firstname,
          surname: userDetails.surname,
          email: userDetails.email,
          job: userDetails.job,
          organisation: userDetails.organisation,
          id: rowToEdit.id,
          other: rowToEdit.other,
        },
      ].sort((a, b) => a.id - b.id),
    );
    setShowModal(false);
    setRowToEdit(null);
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => {
        setShowModal(false);
        setRowToEdit(null);
      }}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
      appElement={document.getElementById('root')}>
      <form>
        <div className="flex justify-between">
          <div className="font-extrabold text-xl text-left">
            Update user details:
          </div>
          <button
            type="button"
            className="bg-white border-platform-cta-error-800 border-2 rounded-md p-2 text-platform-cta-error-800 font-bold hover:bg-platform-cta-error-600 focus:bg-platform-cta-error-800 hover:text-platform-cta-error-400 focus:text-white"
            onClick={() => setShowModal(false)}>
            <FiXCircle />
          </button>
        </div>
        <div className="flex flex-wrap -mx-3 my-2 mb-3">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="firstname">
              First Name
              <input
                className={`appearance-none block w-full py-3 px-4 leading-tight rounded-md border ${
                  rowToEdit.firstname === ''
                    ? 'border-platform-primary-orange-800'
                    : 'border-platform-primary-grey-200'
                } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                id="firstname"
                type="text"
                value={rowToEdit.firstname}
                placeholder="First Name"
                onChange={(e) =>
                  setRowToEdit({ ...rowToEdit, firstname: e.target.value })
                }
              />
            </label>
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="surname">
              Surname
              <input
                className={`appearance-none block w-full py-3 px-4 leading-tight rounded-md border ${
                  rowToEdit.surname === ''
                    ? 'border-platform-primary-orange-800'
                    : 'border-platform-primary-grey-200'
                } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                id="surname"
                type="text"
                value={rowToEdit.surname}
                placeholder="Surname"
                onChange={(e) =>
                  setRowToEdit({ ...rowToEdit, surname: e.target.value })
                }
              />
            </label>
          </div>
        </div>
        <div className="flex flex-col px-3 -mx-3 my-2 mb-2">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="email">
            Email
            <input
              className={`block py-3 px-4 leading-tight rounded-md border ${
                rowToEdit.email === ''
                  ? 'border-platform-primary-orange-800'
                  : 'border-platform-primary-grey-200'
              } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
              id="email"
              type="text"
              size="50"
              value={rowToEdit.email}
              placeholder="Email"
              onChange={(e) =>
                setRowToEdit({ ...rowToEdit, email: e.target.value })
              }
            />
          </label>
        </div>
        <div className="flex flex-col px-3 -mx-3 my-2 mb-2">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="other">
            Job Title
            <input
              className={`block py-3 px-4 leading-tight rounded-md border ${
                rowToEdit.job === ''
                  ? 'border-platform-primary-orange-800'
                  : 'border-platform-primary-grey-200'
              } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
              id="other"
              type="text"
              size="50"
              value={rowToEdit.job}
              placeholder="Job Title"
              onChange={(e) =>
                setRowToEdit({ ...rowToEdit, job: e.target.value })
              }
            />
          </label>
        </div>
        <div className="flex -mx-3 my-2 mb-2">
          <div className="flex flex-col w-full px-3 mb-6 md:mb-0">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="organisation">
              Organisation
              <Select
                classNamePrefix="select-box"
                styles={{
                  container: (base) => ({
                    ...base,
                    width: '365px',
                    margin: 'auto',
                    border:
                      rowToEdit.organisation === null
                        ? '1px solid #F77941'
                        : '1px solid #C2C4C6',
                    borderRadius: '0.375rem',
                    background: 'white',
                  }),
                  control: (base) => ({
                    ...base,
                    border:
                      rowToEdit.organisation === null
                        ? '1px solid #F77941'
                        : '1px solid #C2C4C6',
                    boxShadow: 'none',
                    '&:hover': {
                      border:
                        rowToEdit.organisation === null
                          ? '1px solid #F77941'
                          : '1px solid #C2C4C6',
                      boxShadow: 'none',
                    },
                  }),
                }}
                defaultValue="Choose your organisation"
                value={
                  rowToEdit.organisation !== null
                    ? {
                        label: `${organisations
                          .filter((o) => o.name === rowToEdit.organisation)
                          .map((org) => org.crm_name)}`,
                        value: organisations
                          .filter((o) => o.name === rowToEdit.organisation)
                          .map((org) => org.name),
                      }
                    : null
                }
                options={organisations
                  .sort((a, b) => a.name - b.name)
                  .map((org) => ({
                    value: org.name,
                    label: org.crm_name,
                  }))}
                onChange={(e) =>
                  setRowToEdit({ ...rowToEdit, organisation: e.value })
                }
              />
            </label>
            <div className="text-xs">
              Please select a single Organisation from the list as your
              &apos;home&apos; <br /> organisation, for Procurement Groups we
              will allocate you to <br /> the group at a later stage. If your
              organisation is not in the list, <br /> please select
              &apos;Other&apos;{' '}
            </div>
          </div>
        </div>
        {rowToEdit.organisation === 'OTHER' ? (
          <div className="flex -mx-3 my-2 mb-2">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="other">
                <input
                  className={`appearance-none block w-full py-3 px-4 leading-tight rounded-md border ${
                    rowToEdit.other === ''
                      ? 'border-platform-primary-orange-800'
                      : 'border-platform-primary-grey-200'
                  } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                  id="other"
                  type="text"
                  value={rowToEdit.other}
                  size="50"
                  placeholder="Please specify"
                  onChange={(e) =>
                    setRowToEdit({ ...rowToEdit, other: e.target.value })
                  }
                />
              </label>
            </div>
          </div>
        ) : null}
      </form>
      <div className="flex flex-col items-center w-full md:w-1/2 px-3 py-3 mb-6 md:mb-0">
        <button
          type="button"
          disabled={
            rowToEdit.firstname === '' ||
            rowToEdit.surname === '' ||
            rowToEdit.email === '' ||
            rowToEdit.job === '' ||
            rowToEdit.organisation === '' ||
            (rowToEdit.organisation === 'OTHER' && rowToEdit.other === '')
          }
          onClick={() => handleSaveRow(rowToEdit)}
          className="appearance-none block w-full px-3 py-1 leading-tight rounded-md border bg-white border-platform-primary-orange-800 border-2 rounded-md text-platform-primary-orange-800 font-bold hover:bg-platform-primary-orange-800 focus:bg-platform-cta-success-800 hover:text-platform-ainc-grey-200 focus:text-white disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800">
          Save User Details
        </button>
      </div>
    </Modal>
  );
}

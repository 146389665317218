import React, { useState } from 'react';
import * as XLSX from 'xlsx/xlsx.mjs';
import { utils } from 'xlsx';
import Select from 'react-select';
import axios from 'axios';
import ExcelTemplate from '../assets/adviseinc-user-template.xlsx';
import Table from './Table';
import AdviseWhite from '../assets/AdviseInc_Logo_WHITE.png';
import { baseUrl, headers, organisations, validEmail } from './lookups';

function RegisterInterest() {
  const [form, setForm] = useState({
    firstname: '',
    surname: '',
    email: '',
    job: '',
    organisation: null,
    other: '',
  });

  const [formType, setFormType] = useState('individual');
  const [fileInputKey, setFileInputKey] = useState(0);
  const [validated, setValidated] = useState(false);
  const [response, setResponse] = useState(null);
  const [rows, setRows] = useState([]);

  // Allowed extensions for input file
  const allowedExtensions = ['xls', 'xlsx'];

  const [displayFileError, setDisplayFileError] = useState(false);
  const [displayEmailError, setDisplayEmailError] = useState(false);

  // It will store the file uploaded by the user
  const [file, setFile] = useState('');

  // This function will be called when
  // the file input changes
  const handleFileChange = (e) => {
    setDisplayFileError(false);

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const filenameSplit = inputFile?.name.toLowerCase().split('.');
      const fileExtension = filenameSplit[filenameSplit.length - 1];
      if (!allowedExtensions.includes(fileExtension)) {
        setDisplayFileError(true);
        return;
      }

      // If input type is correct set the state
      setFile(inputFile);
    }
  };

  const handleFileUpload = async () => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const workbook = XLSX.read(e.target.result);
      const data = utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        { header: 1 },
      );
      setRows(
        rows.concat(
          data
            .slice(2)
            .filter((r) => !r.slice(0, 5).includes(undefined))
            .map((r, idx) => ({
              firstname: r[0],
              surname: r[1],
              email: r[2],
              job: r[3],
              organisation: r[4],
              other: r[5] === undefined ? '' : r[5],
              id: rows.length + idx + 1,
            })),
        ),
      );
    };
    reader.readAsArrayBuffer(file);
    setFile('');
    setFileInputKey(fileInputKey + 1); // Forces file input component to reload and so clears it
    setValidated(false);
  };
  const handleRegisterFreshworksIndividual = async () => {
    try {
      await axios.post(
        `${baseUrl}/user-service/freshsales-users`,
        {
          name: `${
            form.firstname.charAt(0).toUpperCase() +
            form.firstname.toLowerCase().slice(1)
          } ${form.surname.charAt(0).toUpperCase()}${form.surname
            .toLowerCase()
            .slice(1)}`,
          first_name:
            form.firstname.charAt(0).toUpperCase() +
            form.firstname.toLowerCase().slice(1),
          last_name:
            form.surname.charAt(0).toUpperCase() +
            form.surname.toLowerCase().slice(1),
          email: form.email.toLowerCase(),
          phone: '',
          mobile: '',
          company_id: 0,
          company_name: form.organisation,
          job_title: form.job,
        },
        { headers },
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('failed to add to fershworks with error');
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
  const handleRegisterIndividual = async () => {
    if (!validEmail.test(form.email)) {
      setDisplayEmailError(true);
    } else {
      setDisplayEmailError(false);
      const responseData = await axios.post(
        `${baseUrl}/user-service/register-interest`,
        [
          {
            first_name:
              form.firstname.charAt(0).toUpperCase() +
              form.firstname.toLowerCase().slice(1),
            surname:
              form.surname.charAt(0).toUpperCase() +
              form.surname.toLowerCase().slice(1),
            email: form.email.toLowerCase(),
            job: form.job,
            organisation: form.organisation,
            other: form.other,
            date_requested: new Date(),
            requested_by: `${form.firstname} ${form.surname}`,
            requested_by_email: form.email,
            requested_by_job_title: form.job,
            crm_code: organisations
              .filter((o) => o.name === form.organisation)
              .map((o) => o.crm_code)
              .join(),
            crm_name: organisations
              .filter((o) => o.name === form.organisation)
              .map((o) => o.crm_name)
              .join(),
            applications_subscription: organisations
              .filter((o) => o.name === form.organisation)
              .map((o) => o.applications_subscription)
              .join(),
            lifecycle_stage: organisations
              .filter((o) => o.name === form.organisation)
              .map((o) => o.lifecycle_stage)
              .join(),
            lifecycle_status: organisations
              .filter((o) => o.name === form.organisation)
              .map((o) => o.lifecycle_status)
              .join(),
            subscription_status: organisations
              .filter((o) => o.name === form.organisation)
              .map((o) => o.subscription_status)
              .join(),
            subscription_types: organisations
              .filter((o) => o.name === form.organisation)
              .map((o) => o.subscription_types)
              .join(),
            approval_granted: false,
          },
        ],
        { headers },
      );
      setResponse(responseData.data?.response);
      if (responseData.data?.response.message === 'success') {
        handleRegisterFreshworksIndividual();
        setForm({
          firstname: '',
          surname: '',
          email: '',
          job: '',
          organisation: null,
          other: '',
        });
      }
    }
  };

  return (
    <div className="flex flex-col h-screen overflow-y-auto bg-platform-primary-orange-400 bg-cover">
      <header className="py-8 sticky top-0 bg-platform-primary-orange-400">
        <div className="justify-between">
          <div className="mx-2 text-white font-extrabold text-3xl">
            AdviseInc Platform Registration
          </div>
          <div className="logo">
            <img
              src={AdviseWhite}
              className="absolute z-20 w-150 top-5 right-5"
              alt=""
            />
          </div>
        </div>
      </header>
      <div className="flex items-center justify-center h-screen bg-platform-primary-orange-400">
        <form className="w-fit max-h-screen md:w-1/2 rounded-md border border-platform-primary-grey-200 bg-white mx-4 px-8 pt-6 pb-8 mb-4">
          <div className="font-extrabold text-l text-left pb-4">
            Are you registering interest for an individual <br /> or on behalf
            of multiple users?
          </div>
          <div className="flex items-center mb-4">
            <input
              type="radio"
              value="individual"
              name="default-radio"
              checked={formType === 'individual'}
              onChange={(e) => {
                setFormType(e.target.value);
                setForm({
                  firstname: '',
                  surname: '',
                  email: '',
                  job: '',
                  organisation: null,
                  other: '',
                });
                setFile('');
              }}
              className="w-4 h-4 text-platform-primary-orange-800 bg-gray-100 border-gray-300 focus:ring-platform-primary-orange-800"
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              htmlFor="default-radio"
              className="ml-2 text-sm font-medium text-gray-700">
              Individual User
            </label>
          </div>
          <div className="flex items-center mb-4">
            <input
              type="radio"
              value="multiple"
              name="multiple-radio"
              checked={formType === 'multiple'}
              onChange={(e) => {
                setFormType(e.target.value);
                setForm({
                  firstname: '',
                  surname: '',
                  email: '',
                  job: '',
                  organisation: null,
                  other: '',
                });
                setFile('');
              }}
              className="w-4 h-4 text-platform-primary-orange-800 bg-gray-100 border-gray-300 focus:ring-platform-primary-orange-800"
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              htmlFor="multiple-radio"
              className="ml-2 text-sm font-medium text-gray-700">
              Multiple Users
            </label>
          </div>
          <div className="font-extrabold text-l text-left pb-4">
            Please complete all fields
          </div>
          <div className="flex flex-wrap -mx-3 my-2 mb-3">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="firstname">
                First Name
                <input
                  className={`appearance-none block w-full py-3 px-4 leading-tight rounded-md border ${
                    form.firstname === ''
                      ? 'border-platform-primary-orange-800'
                      : 'border-platform-primary-grey-200'
                  } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                  id="firstname"
                  type="text"
                  placeholder="First Name"
                  value={form.firstname}
                  onChange={(e) =>
                    setForm({ ...form, firstname: e.target.value })
                  }
                />
              </label>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name">
                Surname
                <input
                  className={`appearance-none block w-full py-3 px-4 leading-tight rounded-md border ${
                    form.surname === ''
                      ? 'border-platform-primary-orange-800'
                      : 'border-platform-primary-grey-200'
                  } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                  id="grid-last-name"
                  type="text"
                  value={form.surname}
                  placeholder="Surname"
                  onChange={(e) =>
                    setForm({ ...form, surname: e.target.value })
                  }
                />
              </label>
            </div>
          </div>
          <div className="flex flex-col px-3 -mx-3 my-2 mb-2">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="email">
              Email
              <input
                className={`block py-3 px-4 leading-tight rounded-md border ${
                  form.email === ''
                    ? 'border-platform-primary-orange-800'
                    : 'border-platform-primary-grey-200'
                } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                id="email"
                type="text"
                size="50"
                value={form.email}
                placeholder="Email"
                onChange={(e) => setForm({ ...form, email: e.target.value })}
              />
            </label>
          </div>
          <div className="flex flex-col px-3 -mx-3 my-2 mb-2">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="other">
              Job Title
              <input
                className={`block py-3 px-4 leading-tight rounded-md border ${
                  form.job === ''
                    ? 'border-platform-primary-orange-800'
                    : 'border-platform-primary-grey-200'
                } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                id="other"
                type="text"
                size="50"
                value={form.job}
                placeholder="Job Title"
                onChange={(e) => setForm({ ...form, job: e.target.value })}
              />
            </label>
          </div>
          {formType === 'individual' ? (
            <>
              <div className="flex -mx-3 my-2 mb-2">
                <div className="flex flex-col w-full px-3 mb-6 md:mb-0">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="organisation">
                    Organisation
                    <Select
                      classNamePrefix="select-box"
                      styles={{
                        container: (base) => ({
                          ...base,
                          width: '365px',
                          margin: 'auto',
                          border:
                            form.organisation === null
                              ? '1px solid #F77941'
                              : '1px solid #C2C4C6',
                          borderRadius: '0.375rem',
                          background: 'white',
                        }),
                        control: (base) => ({
                          ...base,
                          border:
                            form.organisation === null
                              ? '1px solid #F77941'
                              : '1px solid #C2C4C6',
                          boxShadow: 'none',
                          '&:hover': {
                            border:
                              form.organisation === null
                                ? '1px solid #F77941'
                                : '1px solid #C2C4C6',
                            boxShadow: 'none',
                          },
                        }),
                        placeholder: (base) => ({
                          ...base,
                          fontSize: '1em',
                          fontWeight: 400,
                          textTransform: 'capitalize',
                        }),
                      }}
                      placeholder={String(
                        'Select your organisation',
                      ).toLowerCase()}
                      value={
                        form.organisation !== null
                          ? {
                              label: `${organisations
                                .filter((o) => o.name === form.organisation)
                                .map((org) => org.crm_name)}`,
                              value: organisations
                                .filter((o) => o.name === form.organisation)
                                .map((org) => org.name),
                            }
                          : null
                      }
                      options={organisations
                        .sort((a, b) => a.name - b.name)
                        .map((org) => ({
                          value: org.name,
                          label: org.name,
                        }))}
                      onChange={(e) =>
                        setForm({ ...form, organisation: e.value })
                      }
                    />
                  </label>
                  <div className="text-xs">
                    Please select a single Organisation from the list as your
                    &apos;home&apos; <br /> organisation, for Procurement Groups
                    we will allocate you to <br /> the group at a later stage.
                    If your organisation is not in the list, <br /> please
                    select &apos;Other&apos;{' '}
                  </div>
                </div>
              </div>
              {form.organisation === 'OTHER' ? (
                <div className="flex -mx-3 my-2 mb-2">
                  <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="other">
                      <input
                        className={`appearance-none block w-full py-3 px-4 leading-tight rounded-md border ${
                          form.other === ''
                            ? 'border-platform-primary-orange-800'
                            : 'border-platform-primary-grey-200'
                        } bg-white text-xs font-medium text-platform-primary-grey-800 hover:bg-platform-ainc-grey-400 focus:border-platform-primary-orange-800 focus:ring-0`}
                        id="other"
                        type="text"
                        value={form.other}
                        size="50"
                        placeholder="Please specify"
                        onChange={(e) =>
                          setForm({ ...form, other: e.target.value })
                        }
                      />
                    </label>
                  </div>
                </div>
              ) : null}
              <div className="flex flex-col items-center w-full md:w-1/2 px-3 py-3 mb-6 md:mb-0">
                <button
                  type="button"
                  disabled={
                    form.firstname === '' ||
                    form.surname === '' ||
                    form.email === '' ||
                    form.job === '' ||
                    form.organisation === null ||
                    (form.organisation === 'OTHER' && form.other === '')
                  }
                  onClick={handleRegisterIndividual}
                  className="appearance-none block w-full px-3 py-1 leading-tight rounded-md border bg-white border-platform-primary-orange-800 border-2 rounded-md text-platform-primary-orange-800 font-bold hover:bg-platform-primary-orange-600 focus:bg-platform-primary-orange-800 hover:text-platform-ainc-grey-200 focus:text-white disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800">
                  Sign Up here
                </button>
              </div>
              {displayEmailError ? (
                <div className="flex -mx-3 my-2 mb-2">
                  <div
                    className="w-full p-4 mb-4 text-sm rounded-md bg-platform-cta-error-200"
                    role="alert">
                    Please enter a valid NHS or HSCNI email in the form <br />
                    xxx@xxx.xxx.
                  </div>
                </div>
              ) : response !== null ? (
                <div
                  className={`p-4 mb-4 text-sm rounded-md ${
                    response.message === 'success'
                      ? 'bg-platform-cta-success-200'
                      : 'bg-platform-cta-error-200'
                  }`}
                  role="alert">
                  {response.message === 'success' ? (
                    'Your interest has been registered.'
                  ) : (
                    <div>
                      Thank you, it appears that you or a member of your <br />{' '}
                      team have already registered your interest. <br /> Please
                      look out for an email confirming registration
                    </div>
                  )}
                </div>
              ) : null}
            </>
          ) : formType === 'multiple' ? (
            <>
              <div className="font-extrabold text-l text-left py-5">
                To upload multiple users use this&nbsp;
                <button
                  type="button"
                  className="text-blue-600 dark:text-blue-500 hover:underline">
                  <a
                    href={ExcelTemplate}
                    download="adviseinc-user-template.xlsx">
                    template
                  </a>
                </button>
                :
              </div>
              <div className="flex flex-wrap -mx-3 my-2 mb-2">
                <div className="flex flex-col w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <input
                    className={`block w-full cursor-pointer leading-tight file:rounded-md file:border ${
                      file === '' && !displayFileError
                        ? 'border-platform-primary-orange-800 file:border-platform-primary-orange-800'
                        : 'border-platform-primary-grey-200'
                    } file:py-1 file:bg-white file:font-medium file:text-platform-primary-grey-800 border rounded-md hover:bg-platform-ainc-grey-400 focus:outline-platform-primary-orange-800`}
                    id="file_input"
                    type="file"
                    key={fileInputKey}
                    onChange={handleFileChange}
                  />
                  {displayFileError ? (
                    <div
                      className="flex p-4 mt-2 mb-4 text-sm rounded-md bg-platform-cta-error-400"
                      role="alert">
                      Please input a valid file of type .xls or .xlsx
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex flex-col items-center w-full md:w-1/2 px-3 py-6 mb-6 md:mb-0">
                <button
                  type="button"
                  disabled={
                    file === '' || form.firstname === '' || form.surname === ''
                  }
                  onClick={handleFileUpload}
                  className="appearance-none block w-full px-3 py-1 leading-tight rounded-md border bg-white border-platform-primary-orange-800 border-2 rounded-md text-platform-primary-orange-800 font-bold hover:bg-platform-primary-orange-600 focus:bg-platform-primary-orange-800 hover:text-platform-ainc-grey-200 focus:text-white disabled:bg-platform-ainc-grey-400 disabled:text-platform-primary-grey-800 disabled:border-platform-primary-grey-800">
                  Upload multiple users
                </button>
              </div>{' '}
            </>
          ) : null}
        </form>
        {!displayFileError && rows.length > 0 ? (
          <div className="w-full max-h-screen overflow-auto md:w-1/2 max-w-fit bg-white px-8 pt-6 pb-8 mx-4 mb-4 rounded-md border border-platform-primary-grey-200">
            <Table
              validated={validated}
              setValidated={setValidated}
              setRows={setRows}
              rows={rows}
              uploadedBy={`${form.firstname} ${form.surname}`}
              uploadedByEmail={form.email}
              uploadedByJob={form.job}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default RegisterInterest;
